<template>
    <button :class="[$style[buttonClass], $_setStyle]" type="button" @click="action()">
        {{ buttonText }}
    </button>
</template>

<script>
    "use strict";

    import style from "@/mixins/style.js";

    export default {
        "mixins": [
            style,
        ],
        "props": {
            "className": {
                "type": String,
                "required": false,
                "default": "",
            },
            "text": {
                "type": String,
                "required": false,
                "default": "",
            },
            "type": {
                "type": String,
                "required": false,
                "default": "",
            },
        },
        data() {
            return {
                "buttonClass": "",
                "buttonText": "",
            };
        },
        "watch": {
            "text": function() {
                this.setButtonText();
            },
        },
        created() {
            this.setButtonText();
        },
        "methods": {
            setButtonText() {
                const className = this.className;
                const text = this.text;
                const type = this.type;
                if (type === "create") {
                    this.buttonClass = className ? className : "buttonOvalPrimary";
                    this.buttonText = text ? text : "作成";
                }
                else if (type === "update") {
                    this.buttonClass = className ? className : "buttonPrimary";
                    this.buttonText = text ? text : "変更";
                }
                else if (type === "detail") {
                    this.buttonClass = className ? className : "buttonOther";
                    this.buttonText = text ? text : "詳細";
                }
                else if (type === "delete") {
                    this.buttonClass = className ? className : "buttonDelete";
                    this.buttonText = text ? text : "削除";
                }
                else if (type === "back") {
                    this.buttonClass = className ? className : "buttonOvalOther";
                    this.buttonText = text ? text : "戻る";
                }
                else if (type === "next") {
                    this.buttonClass = className ? className : "buttonOvalPrimary";
                    this.buttonText = text ? text : "次へ";
                }
                else if (type === "signup") {
                    this.buttonClass = className ? className : "buttonOvalPrimary";
                    this.buttonText = text ? text : "サインアップ";
                }
                else if (type === "signin") {
                    this.buttonClass = className ? className : "buttonOvalPrimary";
                    this.buttonText = text ? text : "サインイン";
                }
                else if (type === "download") {
                    this.buttonClass = className ? className : "buttonOther";
                    this.buttonText = text ? text : "ダウンロード";
                }
                else if (type === "upload") {
                    this.buttonClass = className ? className : "buttonOther";
                    this.buttonText = text ? text : "アップロード";
                }
                else if (type === "textlink") {
                    this.buttonClass = className ? className : "buttonTextlink";
                    this.buttonText = text ? text : "テキストリンクボタン";
                }
                else {
                    this.buttonClass = className;
                    this.buttonText = text;
                }
            },
            action() {
                this.$emit("action", false);
            },
        },
    };
</script>

<style lang="scss" module>
    .button {
        &Delete {
            @include button--text-link ($delete-color);
        }
        &Cancel {
            @include button--ghost ($cancel-color);
        }
        &Theme {
            @include button ($theme-color);
        }
        &Conversion {
            @include button ($conversion-color);
        }
        &Accent {
            @include button ($accent-color);
        }
        &Ghost {
            @include button--ghost ($ghost-color);
        }
        &Textlink {
            @include button--text-link ($anchor-color);
        }
        &Oval {
            &Delete {
                @include button--oval ($delete-color);
            }
            &Cancel {
                @include button--oval ($cancel-color);
            }
            &Theme {
                @include button--oval ($theme-color);
            }
            &Conversion {
                @include button--oval ($conversion-color);
            }
            &Accent {
                @include button--oval ($accent-color);
            }
        }
    }

    @mixin style ($color) {
        &.button {
            &Primary {
                @include button ($color);
                &:disabled {
                    @include button ($cancel-color);
                    cursor: not-allowed;
                }
            }
            &Other {
                @include button--ghost ($color);
            }
            &Oval {
                &Primary {
                    @include button--oval ($color);
                    &:disabled {
                        @include button--oval ($cancel-color);
                        cursor: not-allowed;
                    }
                }
                &Other {
                    @include button--oval--ghost ($color);
                }
            }
        }
    }

    .isYellow {
        @include style ($yellow);
    }

    .isPink {
        @include style ($pink);
    }

    .isGreen {
        @include style ($green);
    }
</style>
